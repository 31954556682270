import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Goods = () => import('@/views/App/Goods')
const Good = () => import('@/views/App/Good')
const Categories = () => import('@/views/App/Categories')
const Category = () => import('@/views/App/Category')
const Brands = () => import('@/views/App/Brands')
const Brand = () => import('@/views/App/Brand')
const Sellers = () => import('@/views/App/Sellers')
const Seller = () => import('@/views/App/Seller')
const AnalyticsPrices = () => import('@/views/App/AnalyticPrices')
const AnalyticsPricesItem = () => import('@/views/App/AnalyticCategory')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const Partners = () => import('@/views/users/Partners')
const User = () => import('@/views/users/User')

const PartnersInc = () => import('@/views/App/Partner/Partner_Incomes')
const PartnersSale = () => import('@/views/App/Partner/Partner_Sales')
const PartnersStats = () => import('@/views/App/Partner/Partner_Stats')
const PartnersOrd = () => import('@/views/App/Partner/Partner_Orders')
const PartnersSalereports = () => import('@/views/App/Partner/Partner_Salesreport')
const PartnersStocks = () => import('@/views/App/Partner/Partner_Stocks')
const PartnersEx = () => import('@/views/App/Partner/Partner_Excises')

// CRM
const Partner_Orders_CRM = () => import('@/views/App/CRM/Partner_Orders_CRM')
const Partner_Order_CRM = () => import('@/views/App/CRM/Partner_Order_CRM')

const Franchises  = () => import("@/views/users/Franchises");

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: {label: 'Главная'},
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: {label: 'Обзор'},
          component: Dashboard
        },
        {
          path: 'categories',
          name: 'Categories',
          meta: {label: 'Категории'},
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Categories',
              component: Categories,
              meta: {label: 'Обзор'},
            },
            {
              path: '/categories/:id',
              name: 'Category',
              meta: {label: ''},
              component: Category
            },
          ]
        },
        {
          path: 'goods',
          name: 'Goods',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {label: 'Товары'},
          children: [
            {
              path: '',
              name: 'Goods',
              component: Goods,
              meta: {label: 'Обзор'},
            },
            {
              path: ':id',
              name: 'Good',
              meta: {label: ''},
              component: Good
            },
          ]
        },
        {
          path: 'brands',
          name: 'Brands',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {label: 'Бренды'},
          children: [
            {
              path: '',
              name: 'Brands',
              component: Brands,
              meta: {label: 'Обзор'},
            },
            {
              path: ':id',
              name: 'Brand',
              meta: {label: ''},
              component: Brand
            },
          ]
        },
        {
          path: 'sellers',
          name: 'Sellers',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {label: 'Продавцы'},
          children: [
            {
              path: '',
              name: 'Sellers',
              component: Sellers,
              meta: {label: 'Обзор'},
            },
            {
              path: ':id',
              name: 'Seller',
              meta: {label: ''},
              component: Seller
            },
          ]
        },

        {
          path: 'analytics',
          name: 'AnalyticsCategories',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {label: 'Анализ ниш'},
          children: [
            {
              path: '',
              name: 'AnalyticsCategories',
              component: AnalyticsPrices,
              meta: {label: 'Обзор категорий'},
            },
            {
              path: ':id',
              name: 'AnalyticCategory',
              meta: {label: ''},
              component: AnalyticsPricesItem
            },
          ]
        },

        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            },
          ]
        },

        {
          path: 'franchises',
          meta: {
            label: 'Франшизы'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'franchises',
              component: Franchises
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            },
          ]
        },

        {
          path: 'partners',
          meta: {
            label: 'Partners'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Partners',
              component: Partners
            },
            {
              path: 'incomes',
              name: 'Incomes',
              component: PartnersInc
            },
            {
              path: 'sales',
              name: 'Sales',
              component: PartnersSale
            },
            {
              path: 'stats',
              name: 'stats',
              component: PartnersStats
            },
            {
              path: 'orders',
              name: 'Orders',
              component: PartnersOrd
            },
            {
              path: 'salereports',
              name: 'SaleReports',
              component: PartnersSalereports
            },
            {
              path: 'stocks',
              name: 'Stocks',
              component: PartnersStocks
            },
            {
              path: 'excises',
              name: 'Excises',
              component: PartnersEx
            },
          ]
        },

        {
          path: 'orders',
          meta: {
            label: 'Заявки на заказ'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              meta: {
                role: 'MainUser'
              },
              component: Partner_Orders_CRM,
            },
            {
              path: 'manager',
              name: 'CRM_Manager',
              component: Partner_Orders_CRM,
              meta: {
                role: 'Manager'
              }
            },
            {
              path: 'buymanager',
              name: 'CRM_BuyManager',
              component: Partner_Orders_CRM,
              meta: {
                role: 'BuyManager'
              }
            },
            {
              path: 'fullfill',
              name: 'CRM_Fullfill',
              component: Partner_Orders_CRM,
              meta: {
                role: 'Fullfill'
              }
            },
            {
              path: 'regdir',
              name: 'CRM_Regdir',
              component: Partner_Orders_CRM,
              meta: {
                role: 'RegDir'
              }
            },

            {
              path: ':id',
              name: 'Order',
              component: Partner_Order_CRM
            },
          ]
        },

      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

