export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Навигация'],
        name: 'Навигация'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Главная',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'info',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Товары',
        to: '/goods',
        icon: 'cil-list-rich',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Категории',
        to: '/categories',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Бренды',
        to: '/brands',
        icon: 'cil-puzzle',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Продавцы',
        to: '/sellers',
        icon: 'cil-people',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Анализ ниш',
        to: '/analytics',
        icon: 'cil-chart',
      },
    ]
  }
]