<template>
  <router-view></router-view>
</template>

<script>
import nav from "@/containers/_nav";
import router from "@/router";

export default {
  name: 'App',
  mounted() {
    let that = this;
    if (this.$store.state.User.id === 0) {
      window.REST.auth(null, null).then(res => {

        // Ошибка авторизации
        if (res.data.isAuthorized !== true) {
          that.message = res.data.message;
          router.push({name: 'Login'});
          return;
        }

        // Авторизация успешна
        res.data.User.isAuthorized = true;
        that.$store.commit('set', ['User', res.data.User]);

        that.createRoutes();

        window.REST.get("client_order_statuses", null, null, null, null, 1, 1000).then(res => this.$store.commit('set',['statuses', res.data]));

      });
    }
  },
  methods: {
    createRoutes: function () {

      let that = this;

      this.$store.commit('set', ['sidebar_elems', nav]);

      // АДМИН
      if (that.$store.state.User.role === 'Admin') {

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заявки',
          to: '/orders/',
          icon: 'cil-flight-takeoff',
        });


        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavTitle',
          _children: ['Компания'],
          name: 'Компания',
        });

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Пользователи',
          to: '/users',
          icon: 'cil-user',
        });

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Партнёры',
          to: '/partners',
          icon: 'cil-sitemap',
        });

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Франшизы',
          to: '/franchises',
          icon: 'cil-sitemap',
        });


      }


      // Заявки от пользователей
      if (that.$store.state.User.role === 'MainUser') {
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Мои заявки',
          to: '/orders',
          icon: 'cil-flight-takeoff',
        });
      }

      // Отдел сопровождения
      if (that.$store.state.User.role === 'Manager') {
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заявки',
          to: '/orders/manager',
          icon: 'cil-flight-takeoff',
        });
      }

      // Отдел закупа
      if (that.$store.state.User.role === 'BuyManager') {
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заявки',
          to: '/orders/buymanager',
          icon: 'cil-flight-takeoff',
        });
      }

      // фулфилл
      if (that.$store.state.User.role === 'Fullfill') {
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заявки',
          to: '/orders/fullfill',
          icon: 'cil-flight-takeoff',
        });
      }

      // рег дир
      if (that.$store.state.User.role === 'RegDir') {
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заявки',
          to: '/orders/regdir',
          icon: 'cil-flight-takeoff',
        });
      }



      // ОБЫЧНЫЙ ЮЗЕР
      if (that.$store.state.User.role === 'MainUser' || that.$store.state.User.role === 'Guest') {

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavTitle',
          _children: ['Отчеты'],
          name: 'Отчеты'
        });

        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Поставки',
          to: '/partners/incomes',
          icon: 'cil-flight-takeoff',
        });
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Склад',
          to: '/partners/stocks',
          icon: 'cil-window-restore',
        });
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Заказы',
          to: '/partners/orders',
          icon: 'cil-basket',
        });
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Продажи',
          to: '/partners/sales',
          icon: 'cil-chart-line',
        });
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Отчёт по продажам',
          to: '/partners/stats',
          icon: 'cil-chart',
        });
        that.$store.state.sidebar_elems[0]._children.push({
          _name: 'CSidebarNavItem',
          name: 'Отчёт по КиЗам',
          to: '/partners/excises',
          icon: 'cil-library',
        });
      }

    }
  },
  computed: {
    role: function () {
      return this.$store.state.User.role
    },
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
