<template>
  <CBreadcrumb v-bind="props">
    <slot></slot>
  </CBreadcrumb>
</template>

<script>

export default {
  name: 'CBreadcrumbRouterExt',
  components: {
  },
  data: function () {
    return {
    }
  },
  props: {
    addClasses: [String, Array, Object],
    addLinkClasses: [String, Array, Object],
    addLastItemClasses: [String, Array, Object],
  },
  computed: {
    props () {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    },
    items: function () {
      return this.$store.state.Route;
    }
  },
  mounted() {
    this.customItems();
  },
  watch: {
    $route(to, from) {
      this.customItems();
    }
  },
  methods: {
    customItems () {
      this.$store.commit('routeThis', this);
    },
  }
}
</script>
