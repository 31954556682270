import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

import flamerest from 'flamerest';
Vue.use(new flamerest(undefined,"http://wild/", ()=> router.push({name: 'Login'})));

import vuecrud from 'flamecrud';
Vue.use(vuecrud);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

import { CChartBar } from '@coreui/vue-chartjs'
Vue.component('CChartBar', CChartBar)

import CBreadcrumbRouterExt from './containers/Breadcrumb';

Vue.component('CBreadcrumbRouterExt', CBreadcrumbRouterExt);

window.sessionStorage.removeItem("crudschema"); // отключаем CRUD-кеш пока не будет изобретён способ его динамического обновления

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
