import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {

  User: {
    isAuthorized: false,
    id: 0,
    role: '',
    partner: 0,
    name: ''
  },

  Route: {},

  sidebar_elems: [],

  sidebarShow: 'responsive',
  sidebarMinimize: false,

  statuses: []

}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },

  routeThis (state, that) {

    const routes = that.$route.matched.filter(route => {
      return route.name || (route.meta && route.meta.label)
    })
    let route = routes.map(route => {
      const meta = route.meta || {}
      return {
        to: route,
        text: meta.label || route.name
      }
    })

    state.Route = route;

  }
}

export default new Vuex.Store({
  state,
  mutations
})
